<template>
  <Item
    :item="item"
    :is-absolute="isAbsolute"
    :parent="parent"
    :class="{ 'with-bg': withBgColor }"
    class="inline-block icon-item"
  >
    <span
      :class="{
        'rounded-lg': withBgColor,
      }"
      :style="{ backgroundColor: withBgColor ? 'var(--contrastColor)' : null }"
      class="flex justify-center items-center"
    >
      <component
        :is="icon"
        :class="
          withBgColor ? 'w-6 h-6 m-2' : size === 'sm' ? 'w-8 h-8' : 'w-10 h-10'
        "
      />
    </span>
  </Item>
</template>

<script>
import itemMixin from '@/mixins/item'

export default {
  name: 'Icon',

  components: {
    Item: () => import('@/components/Item'),
  },

  mixins: [itemMixin],

  props: {
    isAbsolute: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: '#000',
    },

    size: {
      type: String,
      default: 'default',
    },
  },

  computed: {
    withBgColor() {
      return this.item.props.bgColorBool
    },

    iconName() {
      return this.item.settings.name
    },

    icon() {
      if (!this.iconName) {
        return null
      }

      return () => import(`~/assets/icons-public/${this.iconName}.svg?inline`)
    },
  },
}
</script>

<style lang="postcss">
.icon-item svg {
  > * {
    stroke: var(--contrastColor);
  }
}

.with-bg {
  svg {
    > * {
      stroke: var(--contrastOverlayColor);
    }
  }
}

.icon-add-icon {
  background-image: url('~assets/icons/streamline/24/love-it-add.svg');
}
</style>
